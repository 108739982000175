var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-4"},[_vm._l((_vm.items),function(item){return _c('v-card',{key:item.key,staticClass:"mb-2",staticStyle:{"border":"1px solid #eee","box-shadow":"none"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.name))]),(item.caption)?_c('v-list-item-subtitle',[_vm._v(_vm._s(item.caption))]):_vm._e()],1),_c('v-list-item-action',[_vm._v(" $"+_vm._s(item.price)+" ")])],1)],1)}),_c('div',{staticClass:"text-right mr-4 font-weight-medium py-2 rounded-lg"},[_vm._v(" Total: $"+_vm._s(_vm.total)+" ")]),(_vm.skipCardRequirement && _vm.total === 0)?[_c('div',{staticStyle:{"margin-top":"16px","text-align":"center"}},[_vm._v(" No payment required. Click button below to complete transaction. ")]),_c('v-btn',_vm._b({staticStyle:{"display":"block","width":"100%","margin-top":"16px"},attrs:{"loading":_vm.loading},on:{"click":function($event){_vm.loading = true
        _vm.purchase({})}}},'v-btn',_vm.smallButton,false),[_vm._v("Submit")])]:[(
        _vm.total === 0 &&
        _vm.credit_card_requirement?.label &&
        _vm.credit_card_requirement?.text
      )?_c('div',{staticClass:"text-sm text-center mt-4"},[_c('p',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){_vm.toggleWarning = !_vm.toggleWarning}}},[_c('strong',[_vm._v(_vm._s(_vm.credit_card_requirement.label))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleWarning),expression:"toggleWarning"}]},[_vm._v(" "+_vm._s(_vm.credit_card_requirement.text)+" ")])]):_vm._e(),_c('div',{staticClass:"mt-6"},[_c('credit-card-form',{attrs:{"btn-label":_vm.total > 0 ? 'Confirm & Pay' : 'Submit',"loading":_vm.loading},on:{"submit":_vm.purchase,"update:loading":function($event){_vm.loading=$event}}}),_c('v-img',{staticClass:"d-block mx-auto mt-4",attrs:{"src":require("@/assets/stripe-badge-sm3.png"),"width":"340"}})],1)],_vm._m(0)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-4"},[_c('a',{attrs:{"href":"https://www.reviews.co.uk//company-reviews/store/www.willed.com.au","target":"_blank"}},[_c('img',{attrs:{"src":"https://s3-eu-west-1.amazonaws.com/reviews-global/images/trust-badges/reviews-trust-logo-1.png"}})])])
}]

export { render, staticRenderFns }