<template>
  <div class="pa-4">
    <v-card
      class="mb-2"
      style="border: 1px solid #eee; box-shadow: none"
      v-for="item in items"
      :key="item.key"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{
            item.name
          }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.caption">{{
            item.caption
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action> ${{ item.price }} </v-list-item-action>
      </v-list-item>
    </v-card>
    <div class="text-right mr-4 font-weight-medium py-2 rounded-lg">
      Total: ${{ total }}
    </div>

    <template v-if="skipCardRequirement && total === 0">
      <div style="margin-top: 16px; text-align: center">
        No payment required. Click button below to complete transaction.
      </div>
      <v-btn
        v-bind="smallButton"
        style="display: block; width: 100%; margin-top: 16px"
        @click="
          loading = true
          purchase({})
        "
        :loading="loading"
        >Submit</v-btn
      >
    </template>
    <template v-else>
      <div
        v-if="
          total === 0 &&
          credit_card_requirement?.label &&
          credit_card_requirement?.text
        "
        class="text-sm text-center mt-4"
      >
        <p
          style="text-decoration: underline; cursor: pointer"
          @click="toggleWarning = !toggleWarning"
        >
          <strong>{{ credit_card_requirement.label }}</strong>
        </p>
        <div v-show="toggleWarning">
          {{ credit_card_requirement.text }}
        </div>
      </div>

      <div class="mt-6">
        <credit-card-form
          @submit="purchase"
          :btn-label="total > 0 ? 'Confirm & Pay' : 'Submit'"
          :loading.sync="loading"
        />
        <v-img
          src="@/assets/stripe-badge-sm3.png"
          width="340"
          class="d-block mx-auto mt-4"
        ></v-img>
      </div>
    </template>
    <div class="text-center mt-4">
      <a
        href="https://www.reviews.co.uk//company-reviews/store/www.willed.com.au"
        target="_blank"
      >
        <img
          src="https://s3-eu-west-1.amazonaws.com/reviews-global/images/trust-badges/reviews-trust-logo-1.png"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { functions } from '@/plugins/firebase'

import CreditCardForm from './CreditCardForm'

export default {
  name: 'PaymentCheckout',
  components: { CreditCardForm },
  props: {
    items: {
      default: () => [],
    },
    promoCode: {
      default: null,
    },
    prepaidCode: {
      default: null,
    },
    partnerToken: {
      default: null,
    },
    skipCardRequirement: {
      default: false,
    },
  },
  data: () => ({
    loading: false,
    toggleWarning: false,
  }),
  methods: {
    async purchase(token) {
      functions
        .httpsCallable('clientCheckoutPayment')({
          uid: this.$store.getters['account/auth_user'].uid,
          tokenId: token.id,
          promoCode: this.partnerToken ? null : this.promoCode,
          prepaidCode: this.partnerToken ? null : this.prepaidCode,
          partnerToken: this.partnerToken,
          items: this.items,
          noCardRequired: this.skipCardRequirement,
        })
        .then(async (resp) => {
          if (resp.data.status === 'completed') {
            let query = {
              pt: 's',
              pd: this.total,
              pn: this.products.join('_'),
            }
            if (this.promoCode) {
              query.pc = this.promoCode
            }
            if (this.prepaidCode) {
              query.pp = this.prepaidCode
            }

            if (
              this.products.includes('poa') &&
              !this.products.includes('will')
            ) {
              await this.$router.push({ path: '/poa', query: query })
              this.$router.go()
            } else {
              await this.$router.push({ path: '/', query: query })
            }

            this.$emit('completed')
          }
        })
        .catch((err) => {
          this.$store.commit('app/SET_MESSAGE', {
            type: 'error',
            text: err,
          })
          this.loading = false
        })
    },
  },
  computed: {
    ...mapGetters('app', ['credit_card_requirement']),
    total() {
      return this.items.reduce((a, o) => {
        return a + o.price
      }, 0)
    },
    products() {
      return this.items.map((o) => {
        return o.key
      })
    },
  },
}
</script>

<style scoped>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
</style>
