<template>
  <div class="radius-4 overflow-hidden shadow-4">
    <v-list-item dense>
      <v-list-item-avatar
        color="bg-primary-10 radius-4"
        size="36"
      >
        <v-icon
          color="black"
          size="24"
          >mdi-file-document-outline</v-icon
        >
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="font-weight-bold text-xl">{{ title }}</div>
        <div
          v-if="subtitle"
          class="text-sm opacity-70"
        >
          {{ subtitle }}
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <div class="text-center">
          <div
            v-if="priceFrom && price < priceFrom"
            class="original-price-will"
          >
            ${{ priceFrom }}
          </div>
          <h2 class="text-2xl">${{ price }}</h2>
          <div class="text-sm opacity-60 font-weight-medium">
            {{ priceCaption }}
          </div>
        </div>
      </v-list-item-action>
    </v-list-item>
    <div class="divider" />
    <div
      class="px-4 pt-4"
      v-if="this.$slots.default"
    >
      <slot />
    </div>
    <div class="pa-2">
      <div
        v-for="(item, index) in features"
        :key="`feature-${index}`"
      >
        <v-list-item
          dense
          class="feature-item"
        >
          <v-list-item-avatar
            class="mr-4 my-2"
            size="24"
            color="bg-white"
          >
            <v-icon
              color="primary"
              size="16"
              >mdi-check-bold</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <span class="">{{ item.label }}</span>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-list-item
        v-for="(item, index) in bonusFeatures"
        :key="`bonus-${index}`"
        dense
        class="feature-item mb-2 mt-2"
      >
        <v-list-item-action class="pa-0 ma-0 mr-4">
          <v-chip
            color="primary"
            text-color="black"
            class="font-weight-bold"
            >BONUS</v-chip
          >
        </v-list-item-action>
        <v-list-item-content class="py-0">
          <span class="font-weight-bold">{{ item.label }}</span>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductPrimary',
  props: {
    price: {
      default: null,
    },
    priceFrom: {
      default: null,
    },
    priceCaption: {
      default: null,
    },
    title: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    features: {
      default: () => [],
    },
    bonusFeatures: {
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {},
}
</script>

<style lang="scss">
.original-price-will {
  text-decoration: line-through;
  font-size: 14px;
  margin-top: -2px;
  opacity: 0.7;
}
</style>
